import React, { useState } from 'react'
import { css } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import Lightbox from 'react-image-lightbox'

import {
  mediaQueries,
  space,
  fontSizes,
  colors,
  palette,
} from '../../utils/tokens'

import { ButtonSelector } from '../ButtonSelector'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

export const ProductTableSection = ({ items, className }) => {
  return (
    <section className={className}>
      <ProductTable items={items} />
    </section>
  )
}

const ProductTable = ({ items, }) => {
  return (
    <ul
      css={css({
        listStyle: `none`,
        margin: 0,
      })}
    >
      {items?.length > 0 && (
        items.map((item, idx) => {
          return (
            <li key={item.id} css={css({ margin: `${space[3]}px 0` })}>
              <ProductCard
                image={item.image}
                body={item.body}
                name={item.name}
                price={item.price}
                buttons={item.buttons}
                label={item.label}
                index={idx}
              />
            </li>
          )
        })
      )}
    </ul>
  )
}

const ProductCard = ({ image, body, name, price, buttons, label }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        css={css({
          padding: `${space[3]}px`,
          border: `1px solid ${palette.grey[40]}`,
          borderRadius: `8px`,
          display: `flex`,
          flexDirection: `column`,
          position: `relative`,
          [mediaQueries.lg]: {
            display: `grid`,
            gridTemplateColumns: `250px 1fr`,
            gridGap: `${space[3]}px`,
          },
        })}
      >
        {label && (
          <div
            css={css({
              textAlign: `center`,
              fontSize: fontSizes[2],
              position: `absolute`,
              top: -16,
              right: -16,
              zIndex: 1,
              backgroundColor: colors.success,
              color: colors.white,
              padding: `${space[3]}px ${space[3]}px`,
              fontWeight: `600`,
              letterSpacing: `0.8px`,
              '::after': {
                content: '""',
                display: `block`,
                position: `absolute`,
                right: `-16px`,
                bottom: `-8px`,
                borderWidth: `0 16px 8px`,
                borderStyle: `inset inset inset solid`,
                borderColor: `rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0) ${palette.green[80]}`,
              },
            })}
          >
            <span>{label}</span>
          </div>
        )}
        {image && (
          <button
            onClick={() => setIsOpen(true)}
            aria-label="zoom image"
            css={css({
              cursor: `zoom-in`,
              padding: 0,
              background: `unset`,
              border: `none`,
            })}
          >
            <GatsbyImage
              image={image?.gatsbyImageData}
              alt={image?.title}
              css={css({ margin: `0 auto` })}
            />
          </button>
        )}
        <div
          css={css({
            padding: `${space[3]}px 0`,
          })}
        >
          <h5 css={css({ fontWeight: `600`, fontSize: fontSizes[2] })}>
            {name}
          </h5>
          <p
            css={css({
              fontSize: fontSizes[1],
              fontWeight: `600`,
              margin: `${space[2]}px 0`,
            })}
          >
            <span>Price: </span>
            <span
              css={css({
                color: colors.primaryBrand,
              })}
            >{`$${price}`}</span>
          </p>
          <div
            css={css({
              fontSize: fontSizes[1],
            })}
          >
            {body && renderRichText(body)}
          </div>
          <div
            css={css({
              maxWidth: `250px`,
              marginTop: `${space[3]}px`,
            })}
          >
            {buttons?.length > 0 &&
              buttons.map(button => {
                return <ButtonSelector button={button} key={button.id} />
              })}
          </div>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={image?.file?.url}
          onCloseRequest={() => setIsOpen(false)}
          imagePadding={64}
          imageTitle={name}
        />
      )}
    </>
  )
}