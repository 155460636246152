import slugify from 'slugify'

export const slugifyHeading = node => {
  const slug = slugify(node?.content[0]?.value, {
    strict: true,
    lower: true
  })

  return `${slug}`
}
