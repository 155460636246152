import React from 'react'
import { css } from 'styled-components'
import { GiCheckMark } from 'react-icons/gi'
import { FiX } from 'react-icons/fi'

import { fontSizes, mediaQueries, palette, space } from '../../utils/tokens'

export const ProsConsBlock = ({ pros, cons }) => {
  const listStyle = {
    listStyle: `none`,
  }

  const titleStyle = {
    marginBottom: `${space[3]}px`,
    fontSize: `${fontSizes[3]}`
  }

  const cardStyle = {
    borderRadius: `4px`,
    padding: `${space[4]}px`,
    backgroundColor: palette.green[10],
  }

  return (
    <section
      css={css({
        display: `grid`,
        gridGap: `${space[4]}px`,
        [mediaQueries.md]: {
          gridTemplateColumns: `1fr 1fr`,
        },
      })}
    >
      <div css={cardStyle}>
        <h4 css={css({ color: palette.green[60], ...titleStyle })}>Pros</h4>
        {pros?.length > 0 && (
          <ul css={listStyle}>
            {pros.map((pro, i) => {
              return (
                <li
                  key={i}
                  css={{
                    display: `flex`,
                    padding: `${space[1]}px 0`,
                  }}
                >
                  <span css={{ marginRight: `${space[2]}px` }}>
                    <GiCheckMark css={{ color: palette.green[60] }} />
                  </span>
                  <span>{pro}</span>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <div
        css={css({
          ...cardStyle,
          backgroundColor: palette.red[10],
        })}
      >
        <h4
          css={css({
            ...titleStyle,
            color: palette.red[60],
          })}
        >
          Cons
        </h4>
        {cons?.length > 0 && (
          <ul css={listStyle}>
            {cons.map((con, i) => {
              return (
                <li
                  key={i}
                  css={{
                    display: `flex`,
                    padding: `${space[1]}px 0`,
                  }}
                >
                  <span css={{ marginRight: `${space[2]}px` }}>
                    <FiX css={{ color: palette.red[60] }} />
                  </span>
                  <span>{con}</span>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </section>
  )
}