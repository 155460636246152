import React from 'react'
import { Link } from 'gatsby'
import { css } from 'styled-components'

import {
  palette,
} from '../../utils/tokens'
import siteConfig from '../../utils/siteConfig'

export const Hyperlink = ({ children, node }) => {
  const { uri } = node.data

  const origin = siteConfig.siteUrl

  // we create a url object based on the uri from contentful
  // so we can parse the url
  const linkUrl = new URL(uri)

  let isExternalLink = origin !== linkUrl.origin

  if (isExternalLink) {
    return (
      <a
        href={node.data.uri}
        css={css({
          transition: `0.2s`,
          color: palette.orange[70],
          borderBottom: `2px solid`,
          borderBottomColor: `${palette.orange[30]}`,
          textDecoration: `none`,
          cursor: `pointer`,
          '&:hover': {
            borderBottomColor: palette.orange[70],
          },
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  } else {
    // we use parse the url.pathname here so we can navigate using link
    return (
      <Link
        to={linkUrl.pathname}
        css={css({
          transition: `0.2s`,
          color: palette.orange[70],
          borderBottom: `2px solid`,
          borderBottomColor: `${palette.orange[30]}`,
          textDecoration: `none`,
          cursor: `pointer`,
          '&:hover': {
            borderBottomColor: palette.orange[70],
          },
        })}
      >
        {children}
      </Link>
    )
  }
}
