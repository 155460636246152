import React from 'react'
import { palette, space, fontFamily, lineHeights, fontSizes } from '../../utils/tokens'
import { css } from 'styled-components'

export const CalloutBlockOld = ({
  type,
  title,
  html,
  className,
}) => {

  const getStyle = (type) => {
    if(type === `warning`) {
      return {
        backgroundColor: palette.red[10],
        borderLeftColor: palette.red[50],
        '.callout__title': {
          color: palette.red[90],
        }
      }
    } else if (type === `info`) {
      return {
        backgroundColor: palette.orange[10],
        borderLeftColor: palette.orange[50],
        '.callout__title': {
          color: palette.orange[80],
        }
      }
    } else {
      return {
        backgroundColor: palette.green[10],
        borderLeftColor: palette.green[50],
        '.callout__title': {
          color: palette.green[80],
        },
      }
    }
  }

  const style = getStyle(type)

  return (
    <div
      css={css({
        padding: `${space[4]}px`,
        borderLeft: `4px solid`,
        ...style,
      })}
      className={className}
    >
      <span
        className="callout__title"
        css={css({
          display: `block`,
          marginBottom: `${space[2]}px`,
          fontWeight: `bold`,
        })}
      >
        {title}
      </span>
      {html && (
        <div
          className="callout__body"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
          css={css({
            fontFamily: fontFamily.body,
            lineHeight: lineHeights.body,
            fontSize: fontSizes[1],
          })}
        />
      )}
    </div>
  )
}