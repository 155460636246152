import React from 'react'
import { ButtonBack, ButtonNext } from 'pure-react-carousel'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { css } from 'styled-components'

import {
  colors,
  space,
  boxShadows,
  fontSizes,
} from '../utils/tokens'

const CarouselButtonGroup = ({
  containerCss,
  backButtonCss,
  nextButtonCss,
}) => {
  return (
    <div
      css={css({
        display: `flex`,
        '> :first-child': {
          marginRight: `${space[3]}px`,
        },
      })}
      css={containerCss}
    >
      <ButtonBack
        css={css({
          ...buttonStyle,
        })}
        css={backButtonCss}
      >
        <IoIosArrowBack />
      </ButtonBack>
      <ButtonNext
        css={css({
          ...buttonStyle,
        })}
        css={nextButtonCss}
      >
        <IoIosArrowForward />
      </ButtonNext>
    </div>
  )
}

const buttonStyle = {
  borderRadius: `100%`,
  border: `none`,
  width: `48px`,
  height: `48px`,
  fontSize: fontSizes[3],
  backgroundColor: colors.white,
  boxShadow: `${boxShadows.card}`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
}

export default CarouselButtonGroup