import React from 'react'
import { css } from 'styled-components'
import { breakpoints, fontSizes, space } from '../utils/tokens'
import { ButtonBasic } from './ButtonBasic'
import { QuoteButton } from './Button'

export const ButtonSelector = ({ button, className }) => {
  switch(button?.__typename) {
    case 'ContentfulButtonBasic':
      return <ButtonBasic button={button} className={className}  />
    case 'ContentfulButtonTypeform':
      return (
        <div>
          <QuoteButton
            url={button.link}
            css={css({
              borderRadius: `4px`,
              padding: `${space[1]}px ${space[3]}px`,
              fontSize: fontSizes[0],
            })}
          >
            {button.label}
          </QuoteButton>
          {button.footnote && <Footnote text={button.footnote} />}
        </div>
      )
    default:
      return null
  }
}

const Footnote = ({ text }) => {
  return (
    <div
      css={css({
        maxWidth: `150px`,
        margin: `0 auto`,
        marginTop: `${space[2]}px`,
        textAlign: `center`,
      })}
    >
      <span css={css({ fontStyle: `italic`, fontSize: fontSizes[1] })}>{text}</span>
    </div>
  )
}