import { space, colors, fontSizes, mediaQueries, breakpoints, lineHeights, palette } from './tokens'

export const button = {
  base: {
    borderRadius: `calc(30px)`,
    boxSizing: `border-box`,
    border: `3px solid`,
    textAlign: `center`,
    cursor: `pointer`,
    fontWeight: `bold`,
    textTransform: `uppercase`,
    padding: `0 ${space[4]}px`,
    minHeight: `48px`,
  },
  primary: {
    backgroundColor: colors.primaryBrand,
    borderColor: colors.primaryBrand,
    color: colors.white,
  },
  dark: {
    borderColor: colors.base,
    background: colors.base,
    color: colors.white,
  }
}

export const text = {
  sectionHeading: {
    textAlign: `center`,
    fontWeight: `bold`,
    fontSize: fontSizes[3],
    maxWidth: breakpoints.sm,
    margin: `0 auto`,
    lineHeight: lineHeights.heading,
    color: `inherit`,
    [mediaQueries.md]: {
      fontSize: fontSizes[4],
    },
  },
  sectionSubHeading: {
    textAlign: `center`,
    fontSize: fontSizes[2],
    maxWidth: breakpoints.sm,
    margin: `0 auto`,
    marginTop: `${space[3]}px`,
    fontWeight: `500`,
    color: `inherit`,
    [mediaQueries.md]: {
      lineHeight: lineHeights.subHeading,
      fontSize: fontSizes[3],
    },
  },
}

export const sectionThemeStyle = {
  dark: {
    backgroundColor: colors.base,
    color: `white`,
  },
  light: {
    backgroundColor: `white`,
    color: colors.base,
  },
  mute: {
    backgroundColor: colors.mute,
    color: colors.base,
  }
} 