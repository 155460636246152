export const isUrlTypeform = url => {
  // if url is in form of https://printaworld.typeform.com/to/a2313 "
  // we can do this
  if(url) {
    const parsed = url.split('.')

    return parsed[1] === `typeform`
  }

  return false
}