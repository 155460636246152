import { Link } from 'gatsby'
import React from 'react'
import { css } from 'styled-components'
import { colors, fontSizes, mediaQueries, space } from '../utils/tokens'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


export const ButtonBasic = ({ button, className }) => {
  if(!button) {
    return null
  }

  const { internalLink, externalLink, type, label, footnote } = button

  return (
    <div className={className}>
      <div>
        <LinkSelector button={button} />
      </div>
      {footnote && <Footnote text={footnote} />}
    </div>
  )
}

const LinkSelector = ({ button }) => {
  const { internalLink, externalLink, relativePath, type, label, footnote } = button
  
  const buttonStyle = {
    base: {
      display: `flex`,
      textDecoration: `none`,
      justifyContent: `center`,
      alignItems: `center`,
      borderRadius: `4px`,
      boxSizing: `border-box`,
      border: `3px solid`,
      textAlign: `center`,
      cursor: `pointer`,
      fontWeight: `bold`,
      fontSize: fontSizes[0],
      textTransform: `uppercase`,
      padding: `${space[1]}px ${space[3]}px`,
      minHeight: `48px`,
      [mediaQueries.md]: {
        padding: `${space[1]}px ${space[4]}px`,
      },
    },
    primary: {
      backgroundColor: colors.primaryBrand,
      borderColor: colors.primaryBrand,
      color: colors.white,
    },
    secondary: {
      borderColor: colors.base,
      background: colors.base,
      color: colors.white,
    },
    light: {
      borderColor: colors.white,
      background: colors.white,
      color: colors.base,
    },
    danger: {
      backgroundColor: colors.danger,
      borderColor: colors.danger,
      color: colors.white,
    },
  } 
  
  if (internalLink?.fields?.path) {
    return (
      <Link
        to={internalLink.fields.path}
        css={css({
          ...buttonStyle.base,
          ...buttonStyle[type],
        })}
      >
        {label}
      </Link>
    )
  } else if (externalLink) {
    return (
      <OutboundLink
        href={externalLink}
        rel="noopner noreferrer nofollow"
        target="_blank"
        css={css({
          ...buttonStyle.base,
          ...buttonStyle[type],
        })}
      >
        {label}
      </OutboundLink>
    )
  } else if (relativePath) {
    return (
      <Link
        to={relativePath}
        css={css({
          ...buttonStyle.base,
          ...buttonStyle[type],
        })}
      >
        {label}
      </Link>
    )
  } else {
    return null
  }
}

const Footnote = ({ text }) => {
  return (
    <div
      css={css({
        maxWidth: `150px`,
        margin: `0 auto`,
        marginTop: `${space[2]}px`,
      })}
    >
      <span css={css({ fontStyle: `italic`, fontSize: fontSizes[1] })}>{text}</span>
    </div>
  )
}