import React from 'react'
import { css } from 'styled-components'

import { space, boxShadows, mediaQueries, fontSizes } from '../../utils/tokens'

const ServiceBlock = ({
  name,
  iconUrl,
  iconTitle,
  description,
}) => {
  return (
    <aside
      css={css({
        float: `right`,
        marginLeft: `${space[4]}px`,
        marginTop: `-${space[4]}px`,
        boxShadow: boxShadows.card,
        display: `flex`,
        width: `250px`,
        minHeight: `${space[7]}px`,
        position: `relative`,
        overflow: `hidden`,
      })}
    >
      <div
        css={css({
          width: `100%`,
          height: `inherit`,
          display: `flex`,
          flexDirection: `column`,
        })}
      >
        <div
          css={css({
            padding: `${space[4]}px`,
          })}
        >
          <span
            css={css({
              fontSize: fontSizes[2],
              fontWeight: `bold`,
              marginBottom: `${space[3]}px`,
              display: `block`,
            })}
          >
            {name}
          </span>
          <p
            css={css({
              marginBottom: `${space[3]}px`,
            })}
          >
            Learn more about our {name} service
          </p>
          <img
            src={iconUrl}
            alt={iconTitle}
            css={css({
              height: `${space[6]}px`,
              alignSelf: `flex-end`,
              bottom: `${space[3]}px`,
            })}
          />
        </div>
      </div>
    </aside>
  )
}

export default ServiceBlock