import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'styled-components'

import { space, mediaQueries, fontSizes, colors, palette } from '../utils/tokens'
import { ButtonSelector } from './ButtonSelector'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

export const ProductAffiliate = ({ name, buttons, image, label, body }) => {
  return (
    <aside
      css={css({
        position: `relative`,
        boxShadow: `0 5px 16px rgb(0 0 0 / 10%)`,
        borderRadius: `16px`,
      })}
    >
      <div
        css={css({
          textAlign: `center`,
          fontSize: fontSizes[2],
          position: `absolute`,
          top: -16,
          left: -16,
          zIndex: 1,
          backgroundColor: colors.success,
          color: colors.white,
          padding: `${space[3]}px ${space[3]}px`,
          fontWeight: `600`,
          letterSpacing: `0.8px`,
          '::after': {
            content: '""',
            display: `block`,
            position: `absolute`,
            left: `-16px`,
            bottom: `-8px`,
            borderWidth: `0 16px 8px`,
            borderStyle: `inset solid inset inset`,
            borderColor: `rgba(0,0,0,0) ${palette.green[80]} rgba(0,0,0,0) rgba(0,0,0,0)`,
          },
        })}
      >
        {label && <span>{label}</span>}
      </div>
      <div
        css={css({
          padding: `${space[4]}px`,
          display: `flex`,
          flexDirection: `column`,
          [mediaQueries.lg]: {
            display: `grid`,
            gridTemplateColumns: `1fr 1fr`,
            gridGap: `${space[3]}px`,
            alignItems: `center`,
          },
        })}
      >
        {image?.gatsbyImageData && (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.title}
            css={css({
              margin: `0 auto`,
            })}
          />
        )}
        <div
          css={css({
            flex: 1,
          })}
        >
          {body && (
            <div
              css={css({
                'h4, h5': {
                  fontSize: fontSizes[3],
                  marginBottom: `${space[3]}px`,
                },
                p: {
                  fontSize: fontSizes[1],
                  margin: `${space[3]}px 0`,
                },
              })}
            >
              {renderRichText(body)}
            </div>
          )}
          <div
            css={css({
              marginTop: `${space[4]}px`,
            })}
          >
            {buttons?.length > 0 &&
              buttons.map(button => {
                return <ButtonSelector button={button} key={button.id} />
              })}
            <span
              css={css({
                display: `block`,
                fontSize: fontSizes[0],
                textAlign: `center`,
                marginTop: `${space[2]}px`,
                fontStyle: `italic`,
              })}
            >
              Commisions earned
            </span>
          </div>
        </div>
      </div>
    </aside>
  )
}
