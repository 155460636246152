import React from 'react'
import { Link } from 'gatsby'
import { css } from 'styled-components'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { Paragraph } from '../Article/Paragraph'
import { UnorderedList } from '../Article/UnorderedList'
import { Heading2, Heading3, Heading4 } from '../Article/Headings'
import { Hyperlink } from '../Article/Hyperlink'

import {
  palette,
  space,
} from '../../utils/tokens'

export const CalloutBlock = ({
  body,
  type,
  className,
}) => {
  if(!body) {
    return null
  }
  
  const getStyle = (type) => {
    if(type === `warning`) {
      return {
        backgroundColor: palette.red[10],
        borderLeftColor: palette.red[50],
        '.callout__title': {
          color: palette.red[90],
        }
      }
    } else if (type === `info`) {
      return {
        backgroundColor: palette.orange[10],
        borderLeftColor: palette.orange[50],
        '.callout__title': {
          color: palette.orange[80],
        }
      }
    } else {
      return {
        backgroundColor: palette.green[10],
        borderLeftColor: palette.green[50],
        '.callout__title': {
          color: palette.green[80],
        },
      }
    }
  }

  const style = getStyle(type)

  return (
    <aside
      className={className}
      css={css({ padding: `${space[4]}px ${space[4]}px`, borderLeft: `4px solid`, ...style })}
    >
      {renderRichText(body, options)}
    </aside>
  )
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}</strong>,
    [MARKS.CODE]: text => (
      <code css={css({ backgroundColor: 'red' })}>{text}</code>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <Hyperlink node={node}>{children}</Hyperlink>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return (
        <Link
          to={node.data?.target?.fields?.path}
          css={css({
            transition: `0.2s`,
            color: palette.orange[70],
            borderBottom: `2px solid`,
            borderBottomColor: `${palette.orange[30]}`,
            textDecoration: `none`,
            cursor: `pointer`,
            '&:hover': {
              borderBottomColor: palette.orange[70],
            },
          })}
        >
          {children}
        </Link>
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading2 node={node}>
        {children}
      </Heading2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading3 node={node}>
        {children}
      </Heading3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Heading4 node={node}>
        {children}
      </Heading4>
    ),
  },
}