import React from 'react'
import ReactPlayer from 'react-player/lazy'

import { css } from 'styled-components'

export const BlockVideo = ({ url }) => {
  if (!url) {
    return null
  }

  return (
    <div
      css={css({
        position: `relative`,
        paddingTop: `56.25%`,
      })}
    >
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        css={{
          position: `absolute`,
          top: 0,
          left: 0,
        }}
        controls
      />
    </div>
  )
}