import React from 'react'
import { css } from 'styled-components'

import {
  space,
} from '../../utils/tokens'

export const UnorderedList = ({ children, node }) => {
  return (
    <ul
      css={css({
        marginLeft: `${space[4]}px`,
        marginTop: `${space[3]}px`,
        marginBottom: `${space[3]}px`,
      })}
    >
      {children}
    </ul>
  )
}
