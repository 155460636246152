import React from 'react'
import { FaCheck } from 'react-icons/fa'
import { css } from 'styled-components'
import { space, colors, fontSizes } from '../../utils/tokens'

export const PositiveListBLock = ({ cards }) => {
  if(!cards) {
    return null
  }
  return (
    <ul
      css={css({
        listStyle: `none`,
        fontSize: fontSizes[2],
      })}
    >
      {cards.length > 0 && cards.map((card, id) => {
        const { title } = card

        return (
          <li
            key={id}
            css={css({
              marginBottom: `${space[3]}px`,
              fontWeight: `bold`,
              display: `grid`,
              gridTemplateColumns: `auto 1fr`,
              gridGap: `${space[3]}px`,
            })}
          >
            <FaCheck
              css={css({
                fill: colors.success
              })}
            />
            <span>{title}</span>
          </li>
        )
      })}
    </ul>
  )
}