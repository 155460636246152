import React from 'react'
import { css } from 'styled-components'
import { text } from '../../utils/styles'
import { breakpoints, colors, fontSizes, mediaQueries, space } from '../../utils/tokens'
import { ButtonSelector } from '../ButtonSelector'
import Container from '../Container'

export const CallToActionSection = ({ section }) => {
  if(!section) {
    return null
  }

  const { title, subtitle, buttons, theme } = section

  const sectionThemeStyle = {
    dark: {
      backgroundColor: colors.base,
      color: `white`,
    },
    light: {
      backgroundColor: `white`,
      color: colors.base,
    },
    primary: {
      backgroundColor: colors.primaryBrand,
      color: colors.white,
    }
  }

  return (
    <section
      css={css({
        padding: `${space[5]}px ${space[4]}px`,
        ...sectionThemeStyle[theme],
      })}
    >
      <Container>
        {title && (
          <h2
            css={css({
              ...text.sectionHeading,
            })}
          >
            {title}
          </h2>
        )}
        {subtitle && (
          <p
            css={css({
              color: `inherit`,
              fontSize: fontSizes[2],
              textAlign: `center`,
              fontWeight: `normal`,
              maxWidth: breakpoints.md,
              margin: `0 auto`,
              marginTop: `${space[3]}px`,
            })}
          >
            {subtitle}
          </p>
        )}
        <div
          css={css({
            maxWidth: breakpoints.sm,
            margin: `0 auto`,
            marginTop: `${space[4]}px`,
            display: `flex`,
            justifyContent: `center`,
          })}
        >
          {buttons?.length > 0 &&
            buttons.map(button => {
              return (
                <div
                  key={button.id}
                  css={css({
                    display: `flex`,
                    justifyContent: `center`,
                    width: `100%`,
                    [':not(:first-of-type)']: {
                      marginLeft: `${space[3]}px`,
                    },
                    [mediaQueries.md]: { width: `auto` },
                  })}
                >
                  <ButtonSelector
                    button={button}
                    css={css({
                      width: `100%`,
                      [mediaQueries.md]: { width: `auto` },
                    })}
                  />
                </div>
              )
            })}
        </div>
      </Container>
    </section>
  )
}