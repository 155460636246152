import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { css } from 'styled-components'

import { QuoteButton } from '../Button'

import { button } from '../../utils/styles'
import siteConfig from '../../utils/siteConfig'
import { space, mediaQueries } from '../../utils/tokens'
import { isUrlTypeform } from '../../utils/isUrlTypeForm'

export const ButtonBlock = ({
  type,
  label,
  url
}) => {

  const buttonStyle = {
    ...button.base,
    ...button[type],
    borderRadius: `4px`,
    width: `100%`,
    marginBottom: `${space[2]}px`,
    [mediaQueries.lg]: {
      marginRight: `${space[3]}px`,
      width: `420px`,
    },
    '~ button, ~ a': {
      marginTop: `0 !important`,
    },
    '~ p': {
      marginTop: `${space[4]}px`,
    }
  }
  const origin = siteConfig.siteUrl

  // we create a url object based on the uri from contentful
  // so we can parse the url
  let linkUrl
  let isInternalLink

  if(url) {
    linkUrl = new URL(url)
    isInternalLink = origin === linkUrl.origin
  }

  const showButton = () => {
    if(isInternalLink) {
      return (
        <Link
          to={linkUrl.pathname}
          css={css({
            ...buttonStyle,
            display: `inline-flex`,
            justifyContent: `center`,
            alignItems: `center`,
            textDecoration: `none`,
          })}
        >
          {label}
        </Link>
      )
    }

    if(isUrlTypeform(url)) {
      return (
        <QuoteButton url={url}>{label}</QuoteButton>
      )
    }

    return (
      <button css={css(
        buttonStyle
      )}>{label}</button>
    )
  }
  return showButton()
}

ButtonBlock.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}