import React from 'react'
import { css } from 'styled-components'

import {
  mediaQueries,
  space,
  fontSizes,
} from '../../utils/tokens'

import { slugifyHeading } from '../../utils/slugifyHeading'

export const Heading2 = ({ node, children, className }) => {
  const slug = slugifyHeading(node)

  return (
    <div
      css={css({
        position: `relative`,
        marginTop: `${space[4]}px`,
        marginBottom: `${space[3]}px`,
        [mediaQueries.lg]: {
          marginTop: `${space[5]}px`,
        },
        '&:nth-child(1)': {
          marginTop: 0,
        },
      })}
      className={className}
    >
      <a
        id={slug}
        name={slug}
        css={css({
          scrollMarginTop: `${space[5]}px`,
        })}
      />
      <h2
        css={css({
          fontSize: fontSizes[3],
          marginBottom: `${space[3]}px`,
          fontWeight: `bold`,
          [mediaQueries.lg]: {
            fontSize: fontSizes[4],
          },
        })}
        className={className}
      >
        {children}
      </h2>
    </div>
  )
}

export const Heading3 = ({ node, children, className }) => {
  const slug = slugifyHeading(node)
  return (
    <div
      css={css({
        position: `relative`,
        marginBottom: `${space[3]}px`,
        marginTop: `${space[4]}px`,
        [mediaQueries.lg]: {
          marginTop: `${space[5]}px`,
        },
        '&:nth-child(1)': {
          h3: {
            marginTop: 0,
          },
        },
      })}
    >
      <a
        id={slug}
        name={slug}
        css={css({
          scrollMarginTop: `${space[5]}px`,
        })}
      />
      <h3
        css={css({
          fontSize: fontSizes[2],
          fontWeight: `bold`,
          [mediaQueries.lg]: {
            fontSize: fontSizes[3],
          },
        })}
        className={className}
      >
        {children}
      </h3>
    </div>
  )
}

export const Heading4 = ({ node, children, className }) => {
  const slug = slugifyHeading(node)
  return (
    <div
      css={css({
        '&:nth-child(1)': {
          h4: {
            marginTop: 0,
          },
        },
      })}
    >
      <a
        id={slug}
        css={css({
          scrollMarginTop: `${space[5]}px`,
        })}
      />
      <h4
        css={css({
          fontSize: fontSizes[1],
          marginBottom: `${space[3]}px`,
          marginTop: `${space[4]}px`,
          [mediaQueries.lg]: {
            marginTop: `${space[5]}px`,
            fontSize: fontSizes[2],
          },
        })}
        className={className}
      >
        {children}
      </h4>
    </div>
  )
}
