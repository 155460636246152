import React, { useState, useCallback } from 'react'
import { css } from 'styled-components'
import {
  CarouselProvider,
  Slider,
  Slide,
} from 'pure-react-carousel'
import Lightbox from 'react-image-lightbox'

import {
  space, colors, mediaQueries, fontSizes
} from '../../utils/tokens'

import CarouselButtonGroup from '../CarouselButtonGroup'
import { GatsbyImage } from 'gatsby-plugin-image'

export const GalleryBlockCarousel = ({
  images,
  carouselProviderProps,
}) => {
  if(!images) {
    return null
  }

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <>
      <CarouselProvider
        naturalSlideWidth={600}
        naturalSlideHeight={400}
        totalSlides={images.length}
        visibleSlides={1}
        currentSlide={0}
        infinite
        {...carouselProviderProps}
        css={css({
          position: `relative`,
        })}
      >
        <Slider>
          {images &&
            images.map((block, id) => {
              return (
                <Slide
                  index={id}
                  key={`slide-${id}`}
                  css={css`
                    /** Allows us to use variable width */
                    position: unset !important;
                    height: unset !important;
                    padding-bottom: unset !important;

                    .carousel__inner-slide {
                      position: unset;
                      display: flex;
                      justify-content: center;
                    }
                  `}
                >
                  <div css={css({ position: `relative`, overflow: `hidden`, display: `flex`, flexDirection: `column`, alignItems: `center`, justifyContent: `center` })}>
                    <figure
                      aria-label="open lightbox"
                      onClick={e => openLightbox(e, { index: id })}
                      css={css({ cursor: `zoom-in` })}
                    >
                      <GatsbyImage
                        image={block.image.gatsbyImageData}
                        alt={block?.altText}
                      />
                    </figure>
                    <figcaption
                      css={css({
                        marginTop: `${space[3]}px`,
                        fontSize: fontSizes[1],
                        fontStyle: `italic`,
                        textAlign: `center`,
                      })}
                    >
                      {block.caption}
                    </figcaption>
                  </div>
                </Slide>
              )
            })}
        </Slider>
        <div
          css={css({
            marginTop: `${space[3]}px`,
          })}
        >
          <CarouselButtonGroup
            containerCss={css({
              width: `100%`,
              display: `flex`,
              justifyContent: `center`,
            })}
            backButtonCss={css({
              backgroundColor: colors.base,
              color: colors.white,
              borderRadius: `4px`,
            })}
            nextButtonCss={css({
              backgroundColor: colors.base,
              color: colors.white,
              borderRadius: `4px`,
            })}
          />
        </div>
      </CarouselProvider>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={images[currentImage].image?.lightBox?.images.fallback?.src}
          nextSrc={
            images[(currentImage + 1) % images.length].image?.lightBox?.images
              .fallback?.src
          }
          imageTitle={images[currentImage].altText}
          prevSrc={
            images[(currentImage + images.length - 1) % images.length].image
              ?.lightBox?.images.fallback?.src
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % images.length)
          }
          imagePadding={64}
          enableZoom
        />
      )}
    </>
  )
}