import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'styled-components'

import { space, mediaQueries, fontSizes } from '../../utils/tokens'
const ImageBlock = ({ position, image, alt, caption }) => {
  const style = imageStyle(position)
  const imageData = position === `center` || position === null ? image?.defaultImageData : image?.gatsbyImageData
  return (
    <figure
      css={css({
        marginBottom: `${space[3]}px`,
        display: `flex`,
        flexDirection: `column`,
        [mediaQueries.lg]: {
          ...style,
        },
      })}
    >
      {image?.gatsbyImageData && (
        <GatsbyImage image={imageData} alt={alt} css={css({ margin: `0 auto` })}/>
      )}
      {caption && (
        <figcaption
          css={css({
            margin: `0 auto`,
            marginTop: `${space[3]}px`,
            fontSize: fontSizes[1],
            maxWidth: `300px`,
          })}
        >
          {caption}
        </figcaption>
      )}
    </figure>
  )
}

const imageStyle = (position) => {
  if (position === `left`) {
    return {
      float: `left`,
      paddingRight: `${space[3]}px`,
    }
  } else if (position === `right`) {
    return {
      float: `right`,
      paddingLeft: `${space[3]}px`,
    }
  } else if (position === `center`) {
    return {
      margin: `0 auto`,
    }
  } else {
    return {}
  }
}

export default ImageBlock