import React from 'react'
import { css } from 'styled-components'

import {
  mediaQueries,
  space,
  fontSizes,
  fontFamily,
  palette,
} from '../../utils/tokens'

export const Paragraph = ({ children, node }) => {
  return (
    <p
      css={css({
        lineHeight: `1.5`,
        marginBottom: `${space[3]}px`,
        fontSize: `16px`,
        color: palette.grey[80],
        fontFamily: fontFamily.heading,
        '~ button, ~ a': {
          marginTop: `${space[5]}px`,
        },
        [mediaQueries.lg]: {
          fontSize: fontSizes[2],
        },
        ":last-of-type": {
          marginBottom: 0,
        }
      })}
    >
      {children}
    </p>
  )
}
