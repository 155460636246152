import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { css } from 'styled-components'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { useInView } from 'react-intersection-observer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import loadable from '@loadable/component'

import siteConfig from '../utils/siteConfig'
import { slugifyHeading } from '../utils/slugifyHeading'

import { mainOffset, mediaQueries, space, fontSizes, fontFamily, palette } from '../utils/tokens';
import ImageBlock from './CustomBlocks/ImageBlock';
import ServiceBlock from './CustomBlocks/ServiceBlock'
import InstagramEmbedBlock from './CustomBlocks/InstagramEmbedBlock';
import { CalloutBlockOld } from './CustomBlocks/CalloutBlockOld';
import { ButtonBlock } from './CustomBlocks/ButtonBlock'
import { PositiveListBLock } from './CustomBlocks/PositiveListBlock';
import { ProsConsBlock } from './Blocks/ProsConsBlock';

import { CallToActionSection } from './Section/CallToActionSection'
import { CalloutBlock } from './Blocks/CalloutBlock';
import { ProductAffiliate } from './ProductAffiliate';
import { ProductTableSection } from './Section/ProductTableSection';
import { GalleryBlockCarousel } from './Blocks/GalleryBlockCarousel';
import { BlockVideo } from './BlockVideo';


const GalleryCarouselBlock = loadable(() =>
  import('./CustomBlocks/GalleryCarouselBlock')
)

const ImageComparisonBlock = loadable(() =>
  import('./CustomBlocks/ImageComparisonBlock')
)

const CallToActionBlock = loadable(() =>
  import('./CustomBlocks/CallToActionBlock')
)



const Paragraph = ({ children, node }) => {
  return (
    <p
      css={css({
        lineHeight: `1.5`,
        marginBottom: `${space[3]}px`,
        fontSize: `16px`,
        color: palette.grey[80],
        fontFamily: fontFamily.heading,
        '~ button, ~ a': {
          marginTop: `${space[5]}px`,
        },
        [mediaQueries.lg]: {
          fontSize: fontSizes[2],
        },
      })}
    >
      {children}
    </p>
  )
}

const Hyperlink = ({ children, node }) => {
  const { uri } = node.data


  const origin = siteConfig.siteUrl

  // we create a url object based on the uri from contentful
  // so we can parse the url
  const linkUrl = new URL(uri)

  let isExternalLink = origin !== linkUrl.origin

  if (isExternalLink) {
    return (
      <a
        href={node.data.uri}
        css={css({
          transition: `0.2s`,
          color: palette.orange[70],
          borderBottom: `2px solid`,
          borderBottomColor: `${palette.orange[30]}`,
          textDecoration: `none`,
          cursor: `pointer`,
          '&:hover': {
            borderBottomColor: palette.orange[70],
          },
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  } else {
    // we use parse the url.pathname here so we can navigate using link
    return (
      <Link
        to={linkUrl.pathname}
        css={css({
          transition: `0.2s`,
          color: palette.orange[70],
          borderBottom: `2px solid`,
          borderBottomColor: `${palette.orange[30]}`,
          textDecoration: `none`,
          cursor: `pointer`,
          '&:hover': {
            borderBottomColor: palette.orange[70],
          },
        })}
      >
        {children}
      </Link>
    )
  }
}

const Heading2 = ({ node, children }) => {
  const slug = slugifyHeading(node)

  return (
    <div
      css={css({
        position: `relative`,
        marginTop: `${space[5]}px`,
        marginBottom: `${space[4]}px`,
      })}
    >
      <a
        id={slug}
        name={slug}
        css={css({
          scrollMarginTop: `${space[5]}px`,
        })}
      />
      <h2
        css={css({
          fontSize: fontSizes[3],
          marginBottom: `${space[3]}px`,
          fontWeight: `bold`,
          [mediaQueries.lg]: {
            fontSize: fontSizes[4],
          },
        })}
      >
        {children}
      </h2>
    </div>
  )
}

const Heading3 = ({ node, children }) => {
  const slug = slugifyHeading(node)
  return (
    <div
      css={css({
        position: `relative`,
        marginBottom: `${space[3]}px`,
        marginTop: `${space[4]}px`,
        [mediaQueries.lg]: {
          marginTop: `${space[5]}px`,
        },
      })}
    >
      <a
        id={slug}
        name={slug}
        css={css({
          scrollMarginTop: `${space[5]}px`,
        })}
      />
      <h3
        css={css({
          fontSize: fontSizes[2],
          fontWeight: `bold`,
          [mediaQueries.lg]: {
            fontSize: fontSizes[3],
          },
        })}
      >
        {children}
      </h3>
    </div>
  )
}

const Heading4 = ({ node, children }) => {
  const slug = slugifyHeading(node)
  return (
    <div>
      <a
        id={slug}
        css={css({
          scrollMarginTop: `${space[5]}px`,
        })}
      />
      <h4
        css={css({
          fontSize: `16px`,
          marginBottom: `${space[3]}px`,
          marginTop: `${space[4]}px`,
          fontWeight: `600`,
          [mediaQueries.lg]: {
            marginTop: `${space[5]}px`,
            fontSize: fontSizes[2],
          },
        })}
      >
        {children}
      </h4>
    </div>
  )
}

const Table = ({ tableData }) => {
  const head = tableData.slice(0, 1)
  const body = tableData.slice(1, tableData.length)

  const tdStyle = {
    padding: `${space[3]}px ${space[3]}px !important`,
    fontSize: fontSizes[1],
  }

  return (
    <table>
      <thead>
        <tr>
          {head && head[0].map((item, id) => {
            return (
              <td key={id} css={css({
                ...tdStyle,
                fontWeight: `bold`,
              })}>{item}</td>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {body && body.map((arr, id) => {
          return (
            <tr
              key={id}
              css={css({
                ":nth-of-type(odd)": {
                  backgroundColor: palette.grey[20],
                }
              })}
            >
              {arr.map((item, itemId) => {
                return (
                  <td
                    key={`${itemId}`}
                    css={css({
                      ...tdStyle,
                      ':first-of-type': {
                        fontWeight: `600`,
                      },
                    })}
                  >
                    {item}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const RichArticle = ({ body, location }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true,
  })

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.CODE]: text => (
        <code css={css({ backgroundColor: palette.grey[20], padding: `0 ${space[2]}px`, borderRadius: `2px`, fontSize: fontSizes[2] })}>{text}</code>
      ),
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { description, fluid, title } = node.data.target

        if (!fluid) {
          return null
        }

        return (
          <figure
            css={css({
              marginTop: `${space[4]}px`,
              marginBottom: `${space[4]}px`,
              display: `flex`,
              flexDirection: `column`,
            })}
          >
            <Img
              alt={title}
              fluid={fluid}
              css={css({
                marginBottom: `${space[3]}px`,
              })}
            />
            {description && (
              <figcaption
                css={css({
                  fontStyle: `italic`,
                  textAlign: `center`,
                })}
              >
                {description}
              </figcaption>
            )}
          </figure>
        )
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <Hyperlink node={node} location={location}>
          {children}
        </Hyperlink>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <Link
            to={node.data?.target?.fields?.path}
            css={css({
              transition: `0.2s`,
              color: palette.orange[70],
              borderBottom: `2px solid`,
              borderBottomColor: `${palette.orange[30]}`,
              textDecoration: `none`,
              cursor: `pointer`,
              '&:hover': {
                borderBottomColor: palette.orange[70],
              },
            })}
          >
            {children}
          </Link>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading2 node={node}>{children}</Heading2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading3 node={node}>{children}</Heading3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Heading4 node={node}>{children}</Heading4>
      ),
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
          <ol
            css={css({
              marginLeft: `${space[4]}px`,
              marginTop: `${space[3]}px`,
              marginBottom: `${space[3]}px`,
            })}
          >
            {children}
          </ol>
        )
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul
            css={css({
              marginLeft: `${space[4]}px`,
              marginTop: `${space[3]}px`,
              marginBottom: `${space[3]}px`,
            })}
          >
            {children}
          </ul>
        )
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return (
          <li
            css={css({
              marginBottom: 0,
              '> :first-child': {
                marginBottom: 0,
              },
            })}
          >
            {children}
          </li>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const __typename = node?.data?.target?.__typename || ''

        if (__typename === `ContentfulService`) {
          const { name, fields, icon } = node.data.target
          return (
            <Link to={fields?.path}>
              <ServiceBlock
                name={name}
                iconUrl={icon?.file?.url}
                iconTitle={icon?.title}
              />
            </Link>
          )
        }

        if (__typename === `ContentfulBlockProsCons`) {
          const { pros, cons } = node.data.target

          return (
            <div
              css={css({
                margin: `${space[4]}px 0`,
              })}
            >
              <ProsConsBlock pros={pros} cons={cons} />
            </div>
          )
        }

        if (__typename === `ContentfulSectionCallToAction`) {
          const { title, subtitle, body, theme, butttons } = node.data.target

          return (
            <div
              css={css({
                marginTop: `${space[4]}px`,
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginTop: `${space[5]}px`,
                  marginBottom: `${space[5]}px`,
                },
              })}
            >
              <CallToActionSection section={node.data.target} />
            </div>
          )
        }

        if(__typename === `ContentfulProductAffiliate`) {
          const { name, image, buttons, label, body } = node.data.target
          return (
            <div
              css={css({
                marginTop: `${space[4]}px`,
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginTop: `${space[5]}px`,
                  marginBottom: `${space[5]}px`,
                },
              })}
            >
              <ProductAffiliate
                name={name}
                image={image}
                buttons={buttons}
                label={label}
                body={body}
              />
            </div>
          )
        }

        if (__typename === `ContentfulSectionProductTable`) {
          const { items } = node.data.target
          return (
            <ProductTableSection
              items={items}
              css={css({
                marginTop: `${space[4]}px`,
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginTop: `${space[5]}px`,
                  marginBottom: `${space[5]}px`,
                },
              })}
            />
          )
        }

        if (__typename === `ContentfulBlockImage`) {
          const { altText, caption, image, position } = node.data.target
          return (
            <div
              css={css({
                marginTop: `${space[4]}px`,
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginTop: `${space[5]}px`,
                  marginBottom: `${space[5]}px`,
                },
              })}
            >
              <ImageBlock
                image={image}
                alt={altText}
                position={position}
                caption={caption}
              />
            </div>
          )
        }

        if (__typename === `ContentfulBlockCallout`) {
          return (
            <CalloutBlock
              body={node.data.target.body}
              type={node.data.target.type}
              css={css({
                marginTop: `${space[4]}px`,
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginTop: `${space[5]}px`,
                  marginBottom: `${space[5]}px`,
                },
              })}
            />
          )
        }

        if (__typename === `ContentfulBlockGallery`) {
          return (
            <div
              css={css({
                marginTop: `${space[4]}px`,
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginTop: `${space[5]}px`,
                  marginBottom: `${space[5]}px`,
                },
              })}
            >
              <GalleryBlockCarousel images={node.data.target.images} />
            </div>
          )
        }

        if (__typename === `ContentfulBlockVideo`) {
          const url = node.data.target.url || node.data.target.media?.file?.url

          return (
            <div
              css={css({
                marginTop: `${space[4]}px`,
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginTop: `${space[5]}px`,
                  marginBottom: `${space[5]}px`,
                },
              })}
            >
              <BlockVideo url={url} />
            </div>
          )

        }
        const turnPropertiesIntoPropsObject = properties => {
          return properties.reduce((acc, cv) => {
            const key = cv.key
            const value = cv.value

            if (key && value) {
              return { ...acc, [key]: value }
            }

            return acc
          }, {})
        }

        if (__typename === `ContentfulCustomBlock`) {
          const {
            name,
            assets,
            properties,
            table,
            cards,
            body,
          } = node.data.target

          switch (name) {
            case 'GalleryCarouselBlock': {
              if (!assets) {
                return null
              } else {
                return (
                  <div
                    css={css({
                      margin: `${space[5]}px 0`,
                    })}
                  >
                    <GalleryCarouselBlock images={assets} />
                  </div>
                )
              }
            }
            case 'CallToActionBlock': {
              const props = turnPropertiesIntoPropsObject(properties)
              const image = assets?.[0]

              return (
                <CallToActionBlock
                  imageFluid={image?.fluid}
                  alt={image?.title}
                  {...props}
                />
              )
            }
            case 'Table': {
              return (
                <div
                  css={css({
                    marginTop: `${space[4]}px`,
                    marginBottom: `${space[4]}px`,
                    overflowX: `auto`,
                    [mediaQueries.lg]: {
                      marginTop: `${space[5]}px`,
                      marginBottom: `${space[5]}px`,
                    },
                  })}
                >
                  <Table tableData={table?.tableData} />
                </div>
              )
            }
            case 'ImageBlock': {
              const props = turnPropertiesIntoPropsObject(properties)
              return (
                <div
                  css={css({
                    marginTop: `${space[4]}px`,
                    marginBottom: `${space[4]}px`,
                    [mediaQueries.lg]: {
                      marginTop: `${space[5]}px`,
                      marginBottom: `${space[5]}px`,
                    },
                  })}
                >
                  <ImageBlock
                    fluid={assets?.[0]?.fluid}
                    alt={assets?.[0]?.title}
                    {...props}
                  />
                </div>
              )
            }
            case 'InstagramEmbed': {
              const props = turnPropertiesIntoPropsObject(properties)
              if (props && props.url) {
                const url = new URL(props.url)
                const isInstagram = url.host.split('.').includes(`instagram`)

                if (isInstagram) {
                  return <InstagramEmbedBlock {...props} />
                }
              }
            }
            case 'PositiveListBlock': {
              return <PositiveListBLock cards={cards} />
            }
            case 'ImageComparisonBlock': {
              const props = turnPropertiesIntoPropsObject(properties)

              return (
                <div
                  css={{
                    marginTop: `${space[4]}px`,
                    marginBottom: `${space[4]}px`,
                  }}
                >
                  <ImageComparisonBlock {...props} />
                </div>
              )
            }
            case 'CalloutBlock': {
              const props = turnPropertiesIntoPropsObject(properties)
              return (
                <CalloutBlockOld
                  {...props}
                  html={body?.childMarkdownRemark?.html}
                  css={css({ marginBottom: `${space[3]}px` })}
                />
              )
            }
            case 'ButtonBlock': {
              const props = turnPropertiesIntoPropsObject(properties)

              return <ButtonBlock {...props} />
            }
            case 'MapEmbed': {
              const props = turnPropertiesIntoPropsObject(properties)
              return (
                <div
                  ref={ref}
                  css={css({
                    margin: `${space[5]}px 0`,
                    iframe: {
                      width: `100%`,
                    },
                  })}
                  dangerouslySetInnerHTML={{
                    __html: inView ? props.embed : null,
                  }}
                />
              )
            }
          }
        }
      },
    },
  }

  return (
    <div>{renderRichText(body, options)}</div>
  )
}

export default RichArticle