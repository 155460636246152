import React from 'react'
import styled from 'styled-components'

import { breakpoints } from '../utils/tokens'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${breakpoints.xl};
`

const Container = props => {
  return <Wrapper style={props.style} className={props.className}>{props.children}</Wrapper>
}

export default Container
