import React from 'react'
import InstagramEmbed from 'react-instagram-embed'
import { css } from 'styled-components'

import { space, mediaQueries } from '../../utils/tokens'

const InstagramEmbedBlock = ({
  url,
  maxWidth,
  hideCaption,
  containerTagName,
  protocol,
  inJectScript,
  onLoading,
  onSuccess,
  onAfterRender,
  onFailure,
  position,
}) => {
  return (
    <InstagramEmbed
      url={url}
      maxWidth={maxWidth}
      hideCaption={hideCaption}
      containerTagName={containerTagName}
      protocol={protocol}
      injectScript={inJectScript}
      onLoading={onLoading}
      onSuccess={onSuccess}
      onAfterRender={onAfterRender}
      onFailure={onFailure}
      css={css({
        padding: 0,
        [mediaQueries.lg]: {
          float: `${position}`,
          padding: position ? `0 ${space[3]}px` : null,
        },
        iframe: {
          minWidth: `unset !important`,
        },
      })}
    />
  )
}

export default InstagramEmbedBlock